import { useState } from "react";
import Footer from "../../components/footer";
import data from "./data";

function Blog() {
  const [activeBlog, setActiveBlog] = useState("All");

  return (
    <div data-aos="fade" className="aos-init aos-animate">
      <div className="container mx-auto mb-8 px-4 sm:px-5 md:px-10 lg:px-[60px]">
        <div className="py-12">
          <div className="flex items-center mb-10">
            <h1 className="text-4xl dark:text-white font-semibold after-effect after:left-52">
              Blog
            </h1>
            <div className="h-1 w-24 bg-red-500  ml-5 mt-2"></div>
          </div>

          {Object.keys(data).length !== 0 ? (
            <>
              <div className="flex space-x-4 mt-[40px] w-full justify-start md:justify-end flex-wrap font-medium pb-12">
                <button
                  onClick={() => setActiveBlog("All")}
                  className={`${
                    activeBlog === "All"
                      ? "text-red-500 dark:text-red-500"
                      : "text-gray-600"
                  } font-semibold dark:text-[#A6A6A6]`}
                >
                  All
                </button>
                {Object.entries(data).map(([key, value]) => (
                  <button
                    className={`${
                      activeBlog === key
                        ? "text-red-500 dark:text-red-500"
                        : "text-gray-600"
                    } font-semibold dark:text-[#A6A6A6]`}
                    key={key}
                    onClick={(e) => {
                      setActiveBlog(key);
                      console.log(activeBlog);
                    }}
                  >
                    {key}
                  </button>
                ))}
              </div>

              <div className="grid grid-cols-2 gap-6">
                {Object.entries(data).map(([key, categories]) =>
                  categories.length > 0
                    ? categories.map((category, index) =>
                        key === activeBlog ? (
                          <div
                            data-aos="fade-up"
                            data-aos-duration="700"
                            key={index}
                            className={`${category.bgColor} dark:bg-[#0D0D0D] dark:border-[#1D1D1D] dark:border-2 p-4 rounded-lg overflow-hidden`}
                          >
                            <img
                              src={category.cover}
                              alt={`${index}-${key}`}
                              className="w-full h-40 object-cover  rounded-lg"
                            />
                            <div className="p-4">
                              <p className="text-sm dark:text-[#A6A6A6] ">
                                {key}
                              </p>
                              <h2 className="font-semibold dark:text-white">
                                {category.title}
                              </h2>
                            </div>
                          </div>
                        ) : activeBlog === "All" ? (
                          <div
                            data-aos="fade-up"
                            data-aos-duration="700"
                            key={index}
                            className={`${category.bgColor} dark:bg-[#0D0D0D] dark:border-[#1D1D1D] dark:border-2 p-4 rounded-lg overflow-hidden`}
                          >
                            <img
                              src={category.cover}
                              alt={`${index}-${key}`}
                              className="w-full h-40 object-cover  rounded-lg"
                            />
                            <div className="p-4">
                              <p className="text-sm dark:text-[#A6A6A6] ">
                                {key}
                              </p>
                              <h2 className="font-semibold dark:text-white">
                                {category.title}
                              </h2>
                            </div>
                          </div>
                        ) : (
                          ""
                        )
                      )
                    : ""
                )}
              </div>
            </>
          ) : (
            <div
              data-aos="fade-up"
              data-aos-duration="700"
              className="text-gray-500 dark:text-[#A6A6A6] text-1xl md:text-2xl text-center dark:border-[#1D1D1D] dark:border-2 p-4 rounded-lg md:w-[30rem] py-6 mx-auto"
            >
              <p>Good things take time.</p>
              <p>Be patient for some great articles.</p>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Blog;
