function Card({ title, description, src, bgColor, ...props }) {
  return (
    <div
      {...props}
      className={`px-4 py-5 ${bgColor} rounded-lg flex flex-raw dark:border-2 dark:border-[#1D1D1D] dark:bg-[#111111]`}
    >
      <img src={src} alt={title} className="mb-2 w-12 h-12 mr-2" />
      <div>
        <h3 className="dark:text-white text-xl font-semibold">{title}</h3>
        <p className=" leading-8 text-gray-lite dark:text-[#A6A6A6]">
          {description}
        </p>
      </div>
    </div>
  );
}

export default Card;
