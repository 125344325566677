import SocialBtn from "./socialBtn";
import { ReactComponent as Phone } from "../assets/svg/phone.svg";
import { ReactComponent as Location } from "../assets/svg/location.svg";
import { ReactComponent as Email } from "../assets/svg/email.svg";
import { ReactComponent as Date } from "../assets/svg/date.svg";
import { useLocation } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function RightSideBar() {
  const location = useLocation();

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <section
      className={`z-0 mt-20  lg:mt-0 col-span-12 lg:col-span-4 lg:block h-screen lg:sticky lg:top-44 mb-20 lg:mb-0 ${
        location.pathname !== "/" ? " hidden " : ""
      }`}
    >
      <div className="w-full mb-6 lg:mb-0 mx-auto relative bg-white text-center dark:bg-[#111111] px-6 rounded-[20px] mt-[180px] md:mt-[220px] lg:mt-0 ">
        <img
          src="/images/about/avatar.webp"
          alt="profile pic"
          className="w-[240px] absolute left-[50%] transform -translate-x-[50%] h-[240px] drop-shadow-xl mx-auto rounded-[20px] -mt-[140px]"
        />
        <div className="pb-8">
          <div
            data-aos="fade-up"
            data-aos-duration="700"
            className="pt-[100px] flex flex-col"
          >
            <h1 className="mt-6 mb-4 text-2xl text-center font-bold dark:text-white">
              Soran Taha Aziz
            </h1>
            <span className="w-40 mx-auto mb-4 text-[#7B7B7B] dark:bg-[#1D1D1D] px-5 py-1.5 rounded-lg dark:text-[#A6A6A6] text-center">
              Web Developer
            </span>
            <div className="flex space-x-3 justify-center items-center">
              <SocialBtn
                href="https://www.facebook.com/soran.taha.me"
                className="text-blue-500 py-2 px-4 rounded-md bg-[#F3F6F6] dark:bg-[#1D1D1D]"
                icon="fab fa-facebook-f"
              />
              <SocialBtn
                href="https://twitter.com/soran00079150"
                className="text-blue-400  py-2 px-3 rounded-md bg-[#F3F6F6] dark:bg-[#1D1D1D]"
                icon="fab fa-twitter"
              />
              <SocialBtn
                href="https://www.linkedin.com/in/soran-taha-68097a25b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium"
                className="text-blue-700  py-2 px-3 rounded-md bg-[#F3F6F6] dark:bg-[#1D1D1D]"
                icon="fab fa-linkedin-in"
              />
            </div>
          </div>

          <div
            data-aos="fade-up"
            data-aos-duration="700"
            className="p-7 rounded-2xl mt-7 bg-[#F3F6F6] dark:bg-[#1D1D1D]"
          >
            <div className="flex py-2.5 border-b border-[#E3E3E3] dark:border-[#3D3A3A]">
              <span className="flex-shrink-0  bg-white dark:bg-black text-[#E93B81] shadow-md p-3 rounded-md">
                <Phone />
              </span>
              <div className="text-left ml-2.5">
                <p className="text-xs text-[#44566C] dark:text-[#A6A6A6]">
                  Phone
                </p>
                <p className="dark:text-white break-all">
                  <a
                    className="hover:text-[#FA5252] duration-300 transition"
                    href="tel:+1234567890"
                  >
                    +964 750 837 0388
                  </a>
                </p>
              </div>
            </div>
            <div className="flex py-2.5 border-b border-[#E3E3E3] dark:border-[#3D3A3A]">
              <span className="flex-shrink-0 bg-white dark:bg-black text-[#6AB5B9]  shadow-md  p-3 rounded-md">
                <Location />
              </span>
              <div className="text-left ml-2.5">
                <p className="text-xs text-[#44566C] dark:text-[#A6A6A6]">
                  Location
                </p>
                <p className="dark:text-white break-all">Iraq - Erbil</p>
              </div>
            </div>
            <div className="flex items-center py-2.5 border-b border-[#E3E3E3] dark:border-[#3D3A3A]">
              <span className="flex-shrink-0 h-[fit-content] bg-white dark:bg-black text-[#FD7590] shadow-md p-3 rounded-md">
                <Email />
              </span>
              <div className="text-left ml-2.5">
                <p className="text-xs text-[#44566C] dark:text-[#A6A6A6]">
                  Email
                </p>
                <p className="dark:text-white break-all">
                  <a
                    className="hover:text-[#FA5252] duration-300 transition"
                    href="mailto:ibthemes21@gmail.com"
                  >
                    info@sorantaha.com
                  </a>
                </p>
                <p className="dark:text-white break-all">
                  <a
                    className="hover:text-[#FA5252] duration-300 transition"
                    href="mailto:ibthemes21@gmail.com"
                  >
                    soran.taha.aziz@gmail.com
                  </a>
                </p>
              </div>
            </div>
            <div className="flex py-2.5 undefined">
              <span className="flex-shrink-0 bg-white dark:bg-black text-[#C17CEB] shadow-md  p-3 rounded-md">
                <Date />
              </span>
              <div className="text-left ml-2.5">
                <p className="text-xs text-[#44566C] dark:text-[#A6A6A6]">
                  Birthday
                </p>
                <p className="dark:text-white break-all">March 11, 1995</p>
              </div>
            </div>
          </div>

          <div className="flex justify-center">
            <a
              href="www.google.com"
              download=""
              className="inline-flex items-center bg-gradient-to-r from-[#FA5252] to-[#DD2476] duration-200 transition ease-linear hover:bg-gradient-to-l px-8 py-3 text-lg text-white rounded-[35px] mt-6"
            >
              <img src="/images/download.png" alt="icon" className="mr-2" />
              <span>Download CV</span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
export default RightSideBar;
